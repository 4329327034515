<template>
<div class="examinList" v-loading="pageLoading">
    <div class="left">
      <b v-if="examinList[currentIndex]">{{examinList[currentIndex].type_name}}</b>
      <div class="examin" v-if="examinList.length && examinList[currentIndex]">
        <div class="title">
            {{currentIndex+1}}.{{examinList[currentIndex].title}}
        </div>
        <!-- 单选 判断-->
        <div class="answer" v-if="examinList[currentIndex].type_id == 1 || examinList[currentIndex].type_id == 3">
           <!-- ||(isReview && item.flag == list[currentIndex].result) -->
           <!-- is_correct 0是错误 1正确 2未提交过 -->
          <div
            :class="{
              err: isReview && (currentAnswer == item.flag || currentAnswer == item.value) && currentInfo.is_correct == 0,
              checked:
                !isReview && currentAnswer && (currentAnswer == item.flag || currentAnswer === item.value) || isReview && currentInfo.is_correct!=2 && (item.flag == currentInfo.result || item.value == currentInfo.result) ,
            }"
            class="answerItem"
            v-for="(item, index) in answerList"
            @click="chooseAnswer(item)"
            :key="index"
          >
            <img  v-if="isReview && currentInfo.type_id == 1" :src="
              currentInfo.is_correct == 2 || currentInfo.is_correct == 1 && item.flag != currentAnswer || currentInfo.is_correct == 0 && item.flag != currentAnswer  && item.flag != currentInfo.result ?
                check :
                currentInfo.is_correct == 1 && item.flag == currentInfo.result || currentInfo.is_correct == 0 && item.flag == currentInfo.result  ?  
                checked : checkedRed
              "/>
              <img  v-if="isReview && currentInfo.type_id == 3" :src="
              currentInfo.is_correct == 2 || currentInfo.is_correct == 1 && item.value != currentAnswer || currentInfo.is_correct == 0 && item.value != currentAnswer  && item.value != currentInfo.result ?
                check :
                currentInfo.is_correct == 1 && item.value == currentInfo.result || currentInfo.is_correct == 0 && item.value == currentInfo.result  ?  
                checked : checkedRed
              "/>
            <img  v-if="!isReview" :src="currentAnswer && (currentAnswer == item.flag || currentAnswer === item.value) ? checked : check"/>{{ item.flag }}.{{ item.text }}
          </div>
        </div>
        <!-- 多选-->
        <div class="answer" v-if="examinList[currentIndex].type_id == 2">
          <div
            :class="{ 
              checked: !isReview && currentAnswer.indexOf(item.flag) != -1 || isReview && currentInfo.is_correct == 1 && currentAnswer.indexOf(item.flag) != -1,
              err:isReview && currentInfo.is_correct == 0 && currentAnswer.indexOf(item.flag) != -1
            }"
            class="answerItem"
            v-for="(item, index) in answerList"
            @click="chooseAnswer2(item)"
            :key="index"
          >
          <img v-if="!isReview" :src="currentAnswer.indexOf(item.flag) >-1 ? checked2 : check2"/>
          <img v-if="isReview" :src="
            currentAnswer.indexOf(item.flag) == -1 ? check2 :  
            currentInfo.is_correct == 1 &&  currentAnswer.indexOf(item.flag) > -1 ? checked2 : checkedRed2"
          />
            {{ item.flag }}.{{ item.text }}
          </div>
        </div>
        <!-- 填空 -->
        <div class="answer" v-if="examinList[currentIndex].type_id == 4">
          <!-- || (isReview && list[currentIndex].result.indexOf(item.flag) !=-1)  -->
          <div
            :class="{err:isReview && item.text != JSON.parse(currentInfo.result)[index]}"
            class="answerItem"
            v-for="(item, index) in answerList"
            :key="index"
          >{{ index+1 }}、
          <input type="text" @input="changeAnswer(index,item)" class="inputText" v-model.trim="item.text"/>
          <!-- <img v-if="!isReview" :src="currentAnswer.indexOf(item.flag) >-1 ? checked2 : check2"/>
            {{ item.flag }}.{{ item.text }} -->
          </div>
        </div>
        <!-- 解析 -->
        <div class="explain" v-if="isReview">
           <b>解析</b>
           <div class="rightAnswer">
             正确答案：<span>{{ currentInfo.type_id==3 ? currentInfo.result==1?'对' : '错' : currentInfo.result }}</span>
           </div>
           <p>{{ currentInfo.explain }}</p>
        </div>
      </div>
      <div class="btn">
          <p :class="{active:currentIndex!=0}" @click="changeTitle('pre')">上一题</p>
          <p :class="{active: currentIndex < examinList.length-1}" @click="changeTitle('next')">下一题</p>
        </div>
    </div>
    <div class="right">
       <div class="time" v-if="type==1">
          <p class="title"><img :src="time"/>剩余时间</p>
          <p class="timeCount">{{timeCount}}</p>
       </div>
       <div class="card">
          <p class="title"><img :src="card"/>答题卡</p>
          <ul>
            <!-- is_correct 0是错误 1正确 2未提交过 -->
            <li @click="changeAnswerIndex(index)" v-for="(item,index) in examinList" 
              :class="{
                blue:!isReview && (resultList[index] || examinList[index].user_result),
                green: isReview && examinList[index].is_correct == 1,
                red: isReview && examinList[index].is_correct == 0,
                }" :key="index">
              {{index+1}}
            </li>
          </ul>
       </div>
       <div class="btn" v-if="!isReview" @click="submit">交卷</div>
    </div>
</div>
</template>

<script>
import check from "@/assets/images/check.png"
import checked from "@/assets/images/checked.png"
import checkedRed from "@/assets/images/checkedRed.png"
import check2 from "@/assets/images/check2.png"
import checked2 from "@/assets/images/checked2.png"
import checkedRed2 from "@/assets/images/checkedRed2.png"
import time from "@/assets/images/examinTime.png"
import card from "@/assets/images/examinCard.png"
export default {
  name: "lesson",
  data() {
    return {
      check,
      checked,
      checkedRed,
      check2,
      checked2,
      checkedRed2,
      time,
      card,

      timeLimit:60,
      timeCount:'',
      timer:null,
      type:0, //0模拟 1正式
      pageLoading: true,
      isReview:false,
    currentIndex:-1,
    currentAnswer:'',
    report_id:'',
    list:[],
    examinList:[{
      title:'题目',
      type_id:1,
      options:[{
        flag:'A',
        text:'选项A'
      }]
    },{
      title:'题目2',
      type_id:2,
      options:[{
        flag:'A',
        text:'选项A'
      }]
    }],

    resultList: [],
    answerList: [],
    answerObject:[],
    err:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%88%A0%E9%99%A4.png',
    right:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%AF%B9%E5%8F%B7%20(1).png',
    };
  },
  async mounted() {
    this.examinList = []
    this.id = this.$route.query.id
    this.isReview = this.$route.query.isReview ? true : false
    this.type = this.$route.query.type
    this.getData();
  },
  destroyed() {
    
  },
  computed: {
    uid(){
      return this.$store.state.user.id;
    },
    currentInfo(){
      //  console.log(this.examinList)
        return this.currentIndex > -1 ? this.examinList[this.currentIndex] : {}
    },
    plat() {
      return this.$store.state.plat_id;
    },
    finishList(){
      return this.resultList.filter(item => item.result)
    },

  },
  watch:{
    currentIndex(){

      this.currentAnswer = this.resultList[this.currentIndex] &&  this.resultList[this.currentIndex].result ?  this.resultList[this.currentIndex].result : this.currentInfo.user_result
      if(this.examinList[this.currentIndex].type_id==4){ //填空
        this.answerList =  this.currentAnswer ? JSON.parse(this.currentAnswer).map(item=>{
          let v = {'text':item}
          return v
        }) : new Array(this.currentInfo.number).fill({text:''})
        this.answerObject = JSON.parse(JSON.stringify(this.answerList))
      }else if(this.examinList[this.currentIndex].type_id==3){
        this.answerList = [{
          flag: 'A',
          value: 1,
          text: '对'
        },{
          flag: 'B',
          text: '错',
          value: 2,
        }]
      }else{
        this.answerList = this.examinList[this.currentIndex].options 
        ? JSON.parse(this.examinList[this.currentIndex].options)
        : [];
      }
    },
  },
  methods: {
    // 交卷
  submit() {
    // if(this.resultList.length<this.examinList.length){
    //   this.$toast('题目未全部完成')
    //   return
    // }
    if(this.type == 1 && this.resultList.length == 0){
      this.$toast("至少完成一道题");
      return;
    }
    this.$confirm('确定交卷？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitConfirm()
 
        }).catch(() => {
                  
        });
  },
  async submitConfirm() {
     
     this.pageLoading = true;

     await this.submitTitle()
     // console.log('提交答卷')
     if(this.type != 1){
       this.$router.replace({
         path:'./examinResultImitate',
         query:{
           report_id:this.report_id,
           uid: this.uid,
           test_id: this.id,
         }
       })
       return
     }
     // on confirm
     // console.log("交卷");
     this.$api({
       method: "get",
       url: "/api/test/master",
       data: {
         uid: this.uid,
         test_id: this.id,
         report_id: this.report_id,
         discipline_id: this.$route.query.discipline_id
       },
     }).then((res) => {
       this.pageLoading = false;
       if (res.code == 0) {
         this.$toast("交卷成功");
         let maxInfo = null
           //  status 0未通过 1通过
           maxInfo = this.$until.maxItem(res.data.record,'score')
         // window.localStorage.setItem('examinInfo', JSON.stringify(res.data))
         setTimeout(() => {
           this.$router.replace({
             path: "./examinResult",
             query: {
               score: res.data.score,
               time: res.data.time,
               is_pass: res.data.status,
               maxScore: maxInfo.score,
               is_max_pass: maxInfo.status,
             }
           });
         }, 1500);
       } else {
         this.$toast(res.msg);
       }
     });
   },
   // 切答题卡
   async changeAnswerIndex(index){
      if(!this.isReview){
        await this.submitTitle()
      }
      this.currentIndex = index
    },
  // 切换题目
  async changeTitle(type){
    
      if(type=='pre' && this.currentIndex ==0 || type=='next' && this.currentIndex == this.examinList.length -1){
        return
      }  
      if(!this.isReview){
        await this.submitTitle()
      }
      this.currentIndex = type == "pre" ? this.currentIndex - 1 : this.currentIndex + 1;
  },
  // 提交当前题目
  async submitTitle(){
      if (this.currentAnswer) {
        let param = {
          uid: this.uid,
          test_id: this.id,
          report_id: this.report_id,
          question_id: this.examinList[this.currentIndex].question_id,
          result: this.currentAnswer,
          status:this.currentAnswer == this.examinList[this.currentIndex].result ? 1 : 0,
        }
        this.pageLoading = true
        let res = await this.$api({
          method: "post",
          url:'/api/test/put',
          data:param,
        })
        // console.log('当前题目提交完成')
        this.pageLoading = false
        if(res.code !=0){
          this.$toast(res.msg);
          return
        }
       
      }
    },
    

  // 单选选择答案
  chooseAnswer(item) {
      if(this.isReview) return
      // 判断的话取value值
      this.currentAnswer =  this.examinList[this.currentIndex].type_id==3 ? item.value : item.flag
      let myAnswer = {
        result: this.currentAnswer,
        question_id: this.examinList[this.currentIndex].question_id,
        score: this.examinList[this.currentIndex].score,
      };
      this.$set(this.resultList, this.currentIndex, myAnswer)
      // console.log(this.resultList.length);
    },
  // 多选选择答案
  chooseAnswer2(item) {
      if(this.isReview) return
      let answerArr = this.currentAnswer ? this.currentAnswer.split(",") : [];
      // console.log(answerArr);
      let index = answerArr.findIndex((v) => v == item.flag);
      if (index == -1) {
        answerArr.push(item.flag);
      } else {
        answerArr.splice(index, 1);
      }
      this.currentAnswer = answerArr.sort().join(",");
      // console.log(this.currentAnswer);
      let myAnswer = {
        result: this.currentAnswer,
        question_id: this.examinList[this.currentIndex].question_id,
        score: this.examinList[this.currentIndex].score,
      };
        this.$set(this.resultList, this.currentIndex, myAnswer)
    },
    // 填空
    changeAnswer(index,item){
      this.answerObject[index] = item
      this.answerList = JSON.parse(JSON.stringify(this.answerObject))
      if(this.answerList.findIndex(item => item.text)!=-1){
        this.currentAnswer = JSON.stringify(this.answerList.map(item=>item.text))
      }else{
        this.currentAnswer = ''
      }
      let myAnswer = {
        result: this.currentAnswer,
        question_id: this.examinList[this.currentIndex].question_id,
        score: this.examinList[this.currentIndex].score,
      };
      this.$set(this.resultList, this.currentIndex, myAnswer)
    },
    async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/test/wu/lian",
        data: {
          test_id: this.id,
          uid:this.uid,
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.examinList = res.data.list;
        this.resultList = new Array(this.examinList.length).fill(null)
        this.report_id = res.data.report_id
        this.currentIndex = !this.$route.query.isReview ? 0 : parseInt(this.$route.query.currentIndex)
        if(this.type == 1){
          // this.timeLimit = this.$route.query.time * 60 || 0
    
          let timeLimit = res.data.start_time ? this.$route.query.time * 60 *1000 -  ( new Date().getTime() - new Date(res.data.start_time).getTime()) : this.$route.query.time * 60 *1000
          this.timeLimit = parseInt(timeLimit / 1000)
          this.timer = setInterval(()=>{
            if(this.timeLimit<=0){
              this.submitConfirm()
              clearInterval(this.timer)
            }else{
              let {h,m,s} = this.$until.timeCount(this.timeLimit)
              this.timeCount = `${h} : ${m} : ${s}`
              this.timeLimit--
            }
          },1000)
        }
      } else {
        this.$toast(res.msg);
      }
      
      
    },
 
    
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRouteUpdate");
    // console.log(to);
    next();
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar{
  width: 8px;
  height: 7.6px;
  border-radius: 5px;
  background-color: #F4F4F4;
}
::-webkit-scrollbar-thumb{
  /*// border-radius:5px;*/
  background-color:#DEDEE4;
  border-radius:5px;
  margin: 5px 0;
}
.examinList {
  // min-height: calc(100vh - 230px);
  display: flex;
  justify-content: center;
  width:1200px;
  background: #fff;
  margin: 20px auto;
  display: flex;
  .left{
    flex: 1;
    border-radius: 8px;
    height: 456px;
    background: #f6f9fc;
    
    padding: 20px;
    display: flex;
    flex-direction: column;
    b{
      font-size: 22px;
      color: #333333;
      display: block;
      margin-bottom: 20px;
      margin-left: 10px;
    }
    .btn{
      display: flex;
      justify-content: flex-end;
      p{
        width: 122px;
        height: 34px;
        background: #fff;
        border-radius: 4px;
        text-align: center;
        line-height: 34px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
        display: inline-block;
        color: #2e66d0;
        border: 1px solid #2e66d0;
      }
      p.active{
        background: #2e66d0;
        color: #fff;
      }
    }
  }
  .right{
    margin-left: 20px;
    width: 300px;
    display: flex;
    height: 456px;
    flex-direction: column;
    
    .time,.card{
      background: #f6f9fc;
      width: 100%;
      padding: 10px 0 10px 20px;
      border-radius: 8px;
    }
    .time{
      padding-right: 20px;
    }
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 18px;
        margin: 10px 0;
        img{
          margin-right: 10px;
        }
      }
    .time{
      margin-bottom: 20px;
      
      .timeCount{
        background: #fff;
        border-radius: 10px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        color: #f74456;
      }
    }
    .card{
      flex: 1;
      overflow-y: auto;
      height:100%;
      ul{
        overflow: hidden;
        li{
          float: left;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          border: 1px solid #cbced0;
          line-height: 34px;
          text-align: center;
          color: #333333;
          cursor: pointer;
          margin-right: 20px;
          margin-bottom: 12px;
        }
        li:nth-of-type(5n+5){
          margin-right: 0;
        }
        li.blue{
          color: #fff;
          background: #2e66d0;
          border-color: #2e66d0;
        }
        li.red{
          color: #fff;
          background: #e85555;
          border-color: #e85555;
        }
        li.green{
          color: #fff;
          background: #3cd0b6;
          border-color: #3cd0b6;
        }
      }
    }
    .btn{
      margin-top: 20px;
      height: 53px;
      border-radius: 4px;
      line-height: 53px;
      text-align: center;
      background: #2e66d0;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .examin{
    background: #fff;
    width: 100%;
    border-radius: 8px;
    flex: 1;
    padding: 30px 20px;
    overflow-y: auto;
    margin-bottom: 20px;
    .title{
      font-size: 20px;
      color: #333;
    }
    .answer{
      padding: 20px 16px;
      .answerItem{
        width: 100%;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
          width: 18px;
          margin-right: 10px;
        }
        p{
          flex:1;
          font-size: 16px;
          color: #333;
        }
        span{
            color: #666;
            font-size: 12px;
            width: 20px;
            height: 20px;
            background: #FFFFFF;
            border: 1px solid #C4C4C4;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            margin-right: 7px;
            display: block;
          }
      }
      .checked{
        color: #2e66d0;
      }
      .err{
        color: #FF5F6E;
        span{
          background: #FF5F6E;
          border-color: #FF5F6E;
          color:#fff
        }
      }
    }
    
  }
  
}
</style>

<style lang="scss">
</style>
